import React, { useEffect } from "react";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { loadableReady } from "@loadable/component";
import Baseline from "@mui/material/CssBaseline";

import { Notification } from "client/components";
import { AuthProvider } from "client/hooks/useAuthContext";
import { NotificationProvider } from "client/hooks/useNotification";
import Routes from "client/pages";
import { WithAxios } from "client/utils/WithAxios";
import {
  getBalanceNotifications,
  checkBalanceAndUpdateSim,
} from "./services/balance-notifications";
import useNotification from "client/hooks/useNotification";
import useAuthContext from "client/hooks/useAuthContext";

import { ThemeProvider } from "./context/color";

const cache = createCache({ key: "css", prepend: true });

const AppInitializer = () => {
  const { user } = useAuthContext();
  const { setError, setInfo, setWarning } = useNotification();

  useEffect(() => {
    if (!user.id) return;
    const fetchNotifications = async () => {
      try {
        const notifications = await getBalanceNotifications();

        notifications.forEach((notification) => {
          if (notification.isActive === 0) {
            if (
              ["low_balance", "sim_deactivation"].includes(
                notification.notificationType
              )
            ) {
              setError(
                "Your balance is low and your SIM is deactivated. Please recharge or contact support."
              );
            } else {
              setInfo(
                "You have a notification. Check your account for details."
              );
            }
          }
        });
      } catch (error) {
        setError("Failed to fetch notifications. Please try again later.");
      }
    };

    const sendCheckBalanceAndUpdateSim = async () => {
      try {
        await checkBalanceAndUpdateSim();
      } catch (error) {
        console.error("Failed to call checkBalanceAndUpdateSim API:", error);
      }
    };
    sendCheckBalanceAndUpdateSim();

    fetchNotifications();
  }, [setError, setInfo, user]);

  return null;
};

loadableReady(() => {
  hydrate(
    <BrowserRouter>
      <CacheProvider value={cache}>
        <AuthProvider>
          <ThemeProvider>
            <NotificationProvider>
              <WithAxios>
                <Baseline />
                <Notification />
                <AppInitializer />
                <Routes />
              </WithAxios>
            </NotificationProvider>
          </ThemeProvider>
        </AuthProvider>
      </CacheProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );
});

if (module.hot) module.hot.accept();
